/* roboto-regular - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(""), url("/dist/fonts/roboto-v29-latin-regular.woff2") format("woff2"), url("/dist/fonts/roboto-v29-latin-regular.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-italic - latin */
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local(""), url("/dist/fonts/roboto-v29-latin-italic.woff2") format("woff2"), url("/dist/fonts/roboto-v29-latin-italic.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-700 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local(""), url("/dist/fonts/roboto-v29-latin-700.woff2") format("woff2"), url("/dist/fonts/roboto-v29-latin-700.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-700italic - latin */
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local(""), url("/dist/fonts/roboto-v29-latin-700italic.woff2") format("woff2"), url("/dist/fonts/roboto-v29-latin-700italic.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}